<template>
  <div class="w-full px-3 sm:px-5 md:px-10 lg:px-20 xl:px-40">
    <h2 class="text-2xl font-semibold">Kurs’a Başvuruda Bulun</h2>
    <div class="">
      <form action="">
        <div class="form w-full">
          <div class="form-group flex my-5 w-full">
            <div class="name flex flex-col mt-3 w-full mr-5">
              <label for="name" class="font-semibold">Ad</label>
              <input
                type="text"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
                v-model="$v.user.name.$model"
                @click="user.name = ''"
                :class="{
                  'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                    $v.user.name.$error,
                }"
              />
              <small
                v-if="$v.user.name.$error == true"
                class="form-text text-red-500"
                >Bu alan zorunludur</small
              >
            </div>
            <div class="surname flex flex-col mt-3 w-full">
              <label for="surname" class="font-semibold">Soyad</label>
              <input
                type="text"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
                v-model="$v.user.surname.$model"
                :class="{
                  'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                    $v.user.surname.$error,
                }"
              />
              <small
                v-if="$v.user.surname.$error == true"
                class="form-text text-red-500"
                >Bu alan zorunludur</small
              >
            </div>
          </div>
          <div class="form-group flex my-5 w-full">
            <div class="email flex flex-col mt-3 w-full mr-5">
              <label for="email" class="font-semibold">E-posta adresi</label>
              <input
                type="text"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
                v-model="$v.user.email.$model"
                @click="user.email = ''"
                :class="{
                  'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                    $v.user.email.$error,
                }"
              />
              <small
                v-if="$v.user.email.required == false"
                class="form-text text-red-500"
                >Bu alan zorunludur</small
              >
              <small
                v-if="$v.user.email.$invalid && $v.user.email.$model != ''"
                class="form-text text-red-500"
                >Geçerli bir email adresi giriniz</small
              >
            </div>
            <div class="city flex flex-col mt-3 w-full">
              <label for="city" class="font-semibold">Yaşadığı Şehir</label>
              <input
                type="text"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
                v-model="user.city"
              />
            </div>
          </div>
          <div class="form-group flex my-5 w-full">
            <div class="university flex flex-col mt-3 w-full mr-5">
              <label for="university" class="font-semibold">Üniversite</label>
              <input
                type="text"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
                v-model="user.university"
              />
            </div>
            <div class="department flex flex-col mt-3 w-full">
              <label for="department" class="font-semibold">Bölüm</label>
              <input
                type="text"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
                v-model="user.department"
              />
            </div>
          </div>
          <div class="form-group flex my-5 w-full">
            <div class="class flex flex-col mt-3 w-full mr-5">
              <label for="class" class="font-semibold">Sınıf</label>
               <select
                name=""
                id=""
                v-model="user.class"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
              >
                <option value="">1. Sınıf</option>
                <option value="">2. Sınıf</option>
                <option value="">3. Sınıf</option>
                <option value="">4. Sınıf</option>
                
              </select>
            </div>
            <div class="educationType flex flex-col mt-3 w-full">
              <label for="educationType" class="font-semibold"
                >Öğrenim Türü</label
              >
              <select
                name=""
                id=""
                v-model="user.educationType"
                class="w-full h-12 px-3 border border-gray-400 rounded mt-2"
              >
                <option value="">Birinci Öğretim</option>
                <option value="">İkinci Öğretim</option>
              </select>
            </div>
          </div>
          <div class="form-group mt-5 mb-10 w-full">
            <div class="button text-left">
              <input
                type="file"
                ref="file"
                style="display: none"
                @change="onChange($event)"
              />
              <button
                type="button"
                @click="$refs.file.click()"
                class="bg-darkBlue px-5 py-2 rounded text-white"
              >
                CV EKLE
              </button>
              <div
                class="
                  list
                  flex
                  justify-between
                  items-center
                  mt-10
                  bg-transparentBlue
                  p-5
                  border-darkBlue border
                  rounded
                "
                v-if="user.file"
                id="file"
              >
                <div class="">
                  <p class="text-lg text-darkBlue font-bold">Eklenen CV</p>
                  <p id="" class="text-base text-darkBlue">{{ fileName }}</p>
                </div>

                <button
                  @click.prevent="deleteCV"
                  class="bg-lightRed px-5 py-2 rounded text-white"
                >
                  CV'yi SİL
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="button text-left pb-20">
          <button
            class="bg-lightGreen px-5 py-2 rounded text-white"
            type="submit"
            @click.prevent="formSubmit()"
            :disabled="$v.$invalid"
          >
            BAŞVURU YAP
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import customAxios from "../custom_axios";

export default {
  data() {
    return {
      fileName: "",
      user: {
        file: "",
        name: " ",
        surname: " ",
        email: "email@email.com",
        city: "",
        university: "",
        department: "",
        class: "",
        educationType: "",
      },
    };
  },
  methods: {
    deleteCV(e) {
      if (e.target.tagName === "BUTTON") {
        this.user.file = "";
        this.fileName = "";
        this.$refs.file.value = "";
      }
    },
    onChange(e) {
      // var _this = this;
      // const reader = new FileReader();
      const file = e.target.files[0];
      this.fileName = file.name;
      const fileType = this.fileName.split(".");
      if (
        fileType[1] === "pdf" ||
        fileType[1] === "docx" ||
        fileType[1] === "doc"
      ) {
        if (file) {
          this.fileName = file.name;
          this.user.file = this.$refs.file.files[0];

          // reader.readAsDataURL(file);
          // reader.onload = () => {
          //   if (reader.result) {
          //     _this.userInfo.file = reader.result;
          //   } else {
          //     _this.userInfo.file = "";
          //   }
          // };
        }
      } else {
        alert("pdf, docx ve doc dosyalarını yükleyebilirsiniz");
      }
    },
    formSubmit() {
      var formData = new FormData();

      formData.append("Name", this.userInfo.name);
      formData.append("Surname", this.userInfo.surname);
      formData.append("EMail", this.userInfo.email);
      formData.append("City", this.userInfo.city);
      formData.append("University", this.userInfo.university);
      formData.append("Class", this.userInfo.class);
      formData.append("EducationType", this.userInfo.education);
      formData.append("Department", this.userInfo.department);
      formData.append("CV", this.userInfo.file);

      var config = {
        method: "post",
        url: "/user/register",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      customAxios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  validations() {
    return {
      user: {
        name: { required },
        surname: { required },
        email: { required, email },
      },
    };
  },
};
</script>

<style>
</style>