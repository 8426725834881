import axios from "axios"

const instance = axios.create({
  baseURL: "http://192.168.2.29:18000",
  // headers: {
  //   "Content-type": "multipart/form-data"
  // }
})
// instance.defaults.headers.common[""]
//instance.defaults.headers.set("Access-Control-Allow-Origin: * ")
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default instance;

