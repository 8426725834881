<template>
  <div class="relative z-10">
    <div class="banner">
      <div class="particle-container w-full">
        <vue-particles
          color="#ffffff"
          linesColor="#ffffff"
          :moveSpeed="4"
          :particlesNumber="150"
        ></vue-particles>
      </div>
      <div
        class="
          flex
          relative
          z-20
          justify-between
          px-3
          sm:px-5
          md:px-10
          lg:px-20
          xl:px-40
        "
      >
        <div class="text-white py-10">
          <h2 class="font-bold text-5xl">
            DETAY AKADEMİ'YE <br />
            HOŞGELDİNİZ
          </h2>
          <h3 class="text-3xl my-5 w-5/6 leading-9">
            Kurumumuzca düzenlenen birçok yararlı eğitim sizleri bekliyor.
          </h3>
          <h4 class="text-xl w-4/5 leading-9 opacity-60">
            Eğitimlerimiz hakkında detaylı bilgilere ulaşarak başvuruda
            bulunabilir, kişisel gelişiminize katkı sağlayabilirsiniz.
          </h4>
        </div>
        <div class="py-20 hidden md:block slide-top">
          <img
            style="max-width: 350px"
            :src="require('../img/DetayAkademiEgitimDetaylari.png')"
            alt=""
          />
        </div>
      </div>
    </div>

    <div
      class="
        course-info
        relative
        z-10
        px-3
        sm:px-5
        md:px-10
        lg:px-20
        xl:px-40
        py-10
      "
    >
      <h2 class="text-2xl font-semibold mb-5">
        Kurs İçeriği Hakkında Detaylı Bilgi
      </h2>
      <p>
        {{ description }}
        {{ features }}
      </p>
    </div>
    <div class="course-form">
      <Register />
    </div>
  </div>
</template>

<script>
// import { eventBus } from "../main";
import Register from "../components/RegisterCourse.vue";
import VueParticles from "vue-particles/src/vue-particles/vue-particles.vue";

export default {
  components: {
    Register,
    "vue-particles": VueParticles,
  },
  data() {
    return {
      courses: this.$courses,
      id: 0,
      courseName: "",
      description: "",
      features: "",
      activeTab: "",
      sideActiveTab: "",
    };
  },

  created() {
    var _this = this;
    this.courseName = this.$route.params.course;
    this.courses.forEach((course) => {
      if (course.courseName == _this.courseName) {
        _this.id = course.id;
        _this.courseName = course.courseName;
        _this.description = course.description;
        _this.features = course.features;
      }
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.activeTab = document.querySelectorAll("#educationDetailRoute")[0];
    this.activeTab.className = "flex";
    this.sideActiveTab = document.querySelectorAll("#educationDetailRoute")[1];
    this.sideActiveTab.className = "flex";
  },
  destroyed() {
    this.activeTab = document.querySelectorAll("#educationDetailRoute")[0];
    this.activeTab.className = "hidden";
    this.sideActiveTab = document.querySelectorAll("#educationDetailRoute")[1];
    this.sideActiveTab.className = "hidden";
  },
};
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  background-image: url(../img/slider1.png);
  overflow: hidden;
  .particle-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 40px;
    overflow: hidden;
    z-index: 0;
  }
}

.slide-top {
  animation: slide-top 2s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
</style>

